<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>数据总览</h3>
                <span>（99条）</span>
            </div>
            <User></User>
        </div>
        <div class="cont-box">
            <div class="invoice-upload">
                <div>
                    <!-- 表格 -->
                    <div class="upload-header-tabble">
                        <el-table :show-header="false" :data="tableData" style="width: 100%">
                            <el-table-column prop="date" label="内容" width="950">
                                <template slot-scope="scope">
                                    <div style="display: flex;">
                                        <div class="scope-icon" v-if="scope.row.readId === 0">
                                            <span class="scope-icon-item"></span>
                                        </div>
                                        <span style="margin-left: 10px;display: inline-block;">{{scope.row.tipTitle}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="statuc" label="操作" width="80">
                                <template slot-scope="scope">
                                    <el-button @click="navTo(`/newsDetails?id=${scope.row.id}`)" type="text" size="small" >查看详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :total="tableDataCount"
                            @current-change="currentChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import User from "./../../components/user.vue"
export default {
    components:{
        User
    },
    data() {
        return {
            value: new Date(),
            // 表格数据
            tableData: [],
            tableDataCount: 12,
            code: 1,
        }
    },
    created(){
        this.PostListnews();//消息通知列表
    },
    methods: {
        // 消息通知列表
        PostListnews(){
            let userID = localStorage.getItem('userID')
            this.$api.Listnews({
                userId:userID,
                pagecode:this.code,
                pagesize:10
            }).then(res=>{
                this.tableData = res.data
                this.tableDataCount = res.count
            })
        },
        currentChange(e) {
            this.code = e
            this.PostListnews();
        },
    }
}
</script>
<style scoped>
.content {

    min-height: 100vh;
    background-color: #eee;

}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cont-box {
    width: 90%;
    margin: 0 auto;
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    box-sizing: border-box;
}

.data-title span {
    margin-left: 0.5em;
}

.scope-icon {
    width: 6px;
    height: 6px;
}

.scope-icon-item {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
}





.pagination {
    margin-top: 1em;
    text-align: center;
}
</style>